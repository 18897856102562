import React from "react"
import Icon from "components/Icon"
import ListItem from "./ListItem"
import Image from "components/Image"

import { BenefitsListStyled, ListItemIcon, ListItemText } from "./styles"

export default function BenefitsList({ listItem, compact }) {
  return (
    <BenefitsListStyled compact={compact}>
      {listItem.map(({ listItem, listItemIcon, listItemIconImage }, idx) => (
        <ListItem key={`listItem-${listItem}-${idx}`} compact={compact}>
          <ListItemIcon>
            {listItemIconImage ? (
              <Image
                fluid={listItemIconImage[0]}
                aspectRatio={listItemIconImage.width / listItemIconImage.height}
                objectFit='contain'
              />
            ) : (
              <Icon
                type={listItemIcon}
                width='48px'
                height='48px'
                fillPrimary='rgb(0, 177, 172)'
                currentColor={null}
              />
            )}
          </ListItemIcon>
          <ListItemText>{listItem}</ListItemText>
        </ListItem>
      ))}
    </BenefitsListStyled>
  )
}
