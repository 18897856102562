import styled from "@emotion/styled";
import media from "utils/mediaqueries";

export const BenefitsListStyled = styled("ul")(
  {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  (props) => ({
    justifyContent: props.compact && "center",
    margin: props.compact && "50px 0",
  })
);

export const ListItemIcon = styled("div")({
  minWidth: "70px",
  textAlign: "center",
});

export const ListItemText = styled("div")({
  paddingLeft: "30px",
  paddingRight: "30px",
  fontSize: "15px",
});

export const StyledListItem = styled("li")(
  {
    marginBottom: "8vh",
    listStyle: "none",
    paddingRight: "10px",
    width: "50%",
    // single item on a row at mobile
    [media(0, "mobile")]: {
      width: "100%",
    },
    display: "flex",
    alignItems: "center",
  },
  (props) => ({
    margin: props.compact && "20px 25px",
    width: props.compact && "auto",
  })
);
