import React from "react";
import { styled } from "utils/emotion";
import { graphql } from "gatsby";
import media from "utils/mediaqueries";
import SwitchTheme from "components/SwitchTheme";
import Overlay from "components/Overlay";
import BenefitsList from "components/BenefitsList";

const StyledHeadline = styled("h3")({
  fontWeight: 900,
  fontSize: "2rem",
  marginRight: "10vw",
  marginBottom: "10vh",
});

const Headline = ({ listHeadline }) => {
  const capitalHeaderline = listHeadline ? listHeadline.toUpperCase() : " ";
  return (
    <StyledHeadline>
      {
        // add a break after every word
        capitalHeaderline.split(" ").map((substring, idx) => (
          <span key={`sub-${substring}-${idx}`}>
            {substring}
            <br />
          </span>
        ))
      }
    </StyledHeadline>
  );
};

const ContentWrapperStyled = styled("div")({
  position: "relative",
  padding: "0 45px 0 45px",
  [media(0, "mobile")]: {
    width: "100%",
  },
  [media("tablet")]: {
    width: "80%",
  },
  [media("desktop")]: {
    width: "65%",
    marginLeft: "11%",
    maxWidth: "960px",
  },
});

const ListWrapperStyled = styled("div")({
  padding: "70px 0",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 1,
  // [media(0, 'mobile')]: {
  //   paddingLeft: '45px',
  // },
});

const List = ({ pageBuilder } /* :PageBuilderListInterface */) => {
  const { listItem, listHeadline, listTheme } = pageBuilder; // ListItemInterface[]
  return (
    <SwitchTheme name={listTheme && listTheme[0]}>
      <ListWrapperStyled>
        <Overlay background="sectionImageBackground" />

        <ContentWrapperStyled>
          <Headline listHeadline={listHeadline} />
          <BenefitsList listItem={listItem} />
        </ContentWrapperStyled>
      </ListWrapperStyled>
    </SwitchTheme>
  );
};

export default List;

export const query = graphql`
  fragment PageBuilderListQuery on Craft_PageBuilderList {
    listItem {
      listItem
      listItemIcon
      listItemIconImage {
        ...CraftImageFluid
      }
    }
    listHeadline
    listTheme
  }
`;
